import { Controller } from "@hotwired/stimulus";

const INTERIA_AMOUNT = 20;

export default class extends Controller {
  static targets = ["image", "item"];
  connect() {
    // Detect touch
    const ua = window.navigator.userAgent;

    const isMobile = ua.match(
      /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
    );
    this.isMobile = false;

    if ("ontouchstart" in document.documentElement && isMobile) {
      this.isMobile = true;
      return;
    }

    // Vars
    this.xPos = 0;
    this.yPos = 0;
    this.mouseX = 0;
    this.mouseY = 0;
    this.activeSlug = null;

    this.handleRaf = this.handleRaf.bind(this);
    requestAnimationFrame(this.handleRaf);
  }

  disconnect() {
    cancelAnimationFrame(this.handleRaf);
  }

  handleMouseEnter(event) {
    if (this.isMobile) {
      return;
    }

    this.activateImage(event.currentTarget.getAttribute("data-slug"));
  }

  handleMouseMove(event) {
    if (this.isMobile) {
      return;
    }

    this.mouseX = event.pageX - event.currentTarget.offsetLeft;
    this.mouseY = event.pageY - event.currentTarget.offsetTop;
  }

  handleKeyUp(event) {
    if (event.key === "Escape") {
      this.deactivateImage();
    }
  }

  handleMouseLeave() {
    this.deactivateImage();
  }

  activateImage(slug) {
    this.activeSlug = slug;
    const img = this.findImage(this.activeSlug);
    if (img) {
      img.style.opacity = 1;
    }
  }

  deactivateImage() {
    if (this.activeSlug) {
      const img = this.findImage(this.activeSlug);
      if (img) {
        img.style.opacity = 0;
      }
      this.activeSlug = null;
    }
  }

  findImage(slug) {
    return this.imageTargets.find((el) => {
      return el.getAttribute("data-slug") === slug;
    });
  }

  handleRaf() {
    this.xPos += (this.mouseX - this.xPos) / INTERIA_AMOUNT;
    this.yPos += (this.mouseY - this.yPos) / INTERIA_AMOUNT;

    this.imageTargets.forEach((img) => {
      img.style.transform = `translate(${this.xPos + 30}px, ${
        this.yPos + 30
      }px)`;
    });

    requestAnimationFrame(this.handleRaf);
  }
}
